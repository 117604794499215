import { FC, useCallback, useContext, useMemo } from "react";
import { UserContext } from "App";
import { Button, DatePicker, Flex, Form, Input, notification, Popconfirm, Select, Space, Tabs } from "antd";
import { isMobile } from "react-device-detect";
import ButtonClose from "Components/ButtonClose";
import API from "Api";
import Cookies from "js-cookie";
import dayjs from "dayjs";
import { DeleteOutlined } from "@ant-design/icons";
import { getAuth, updatePassword, EmailAuthProvider, reauthenticateWithCredential } from "firebase/auth";
import firebaseApp from "../../firebase";
import { useQueryClient, useSuspenseQuery } from "@tanstack/react-query";
import { find } from "lodash";

const Profile: FC<{ setUser: Function }> = ({ setUser }) => {
  const user = useContext(UserContext);
  const [notify, context] = notification.useNotification();

  const layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 14 },
  };

  const { data: userProps } = useSuspenseQuery({
    queryKey: ["props", user?.id],
    queryFn: API.user.getProps,
    staleTime: Infinity,
  });

  const client = useQueryClient();

  const onSubmit = (values: any) => {
    API.user.updateProp("GENDR", values.sex).then(() => client.invalidateQueries({ queryKey: ["props", user?.id] }));

    API.user
      .update(values)
      .then(() => setUser((prev: any) => ({ ...prev, ...values })))
      .then(() => Cookies.set("user", JSON.stringify({ ...values, isEnglish: user?.isEnglish }), { expires: 24 * 60 * 60 * 31 }))
      .then(() => notify.success({ message: "Аккаунт обновлен!" }));
  };

  const [form] = Form.useForm();

  const resetPassword = useCallback(
    async ({ password, oldPassword }: any) => {
      const { currentUser } = getAuth(firebaseApp);

      if (currentUser) {
        try {
          const creds = EmailAuthProvider.credential(user?.email ?? "", oldPassword);
          await reauthenticateWithCredential(currentUser, creds);
        } catch (e) {
          notify.error({ message: (e as any).code });
        }

        await updatePassword(currentUser, password);

        form.resetFields();
        notify.success({ message: "Пароль обновлен!" });
      }
    },
    [form, notify, user?.email],
  );

  const sex = useMemo(() => find(userProps, ["name", "GENDR"]), [userProps]);

  return (
    <div>
      <Flex justify={"flex-end"}>
        <ButtonClose />
      </Flex>
      <Tabs
        items={[
          {
            key: "0",
            label: "Аккаунт",
            children: (
              <Form
                initialValues={{ ...user, birthday: user?.birthday ? dayjs(user?.birthday) : undefined, sex }}
                {...layout}
                title={"Профиль пользователя"}
                onFinish={onSubmit}
              >
                <Form.Item name={"email"} label={"Email"}>
                  <Input readOnly />
                </Form.Item>
                <Form.Item name={"name"} label={"Username"} rules={[{ required: true }]}>
                  <Input />
                </Form.Item>
                <Form.Item name={"familyName"} label={"Фамилия"}>
                  <Input />
                </Form.Item>
                <Form.Item name={"givenName"} label={"Имя"}>
                  <Input />
                </Form.Item>
                <Form.Item name={"phone"} label={"Телефон"}>
                  <Input />
                </Form.Item>
                <Form.Item name={"sex"} label={"Пол"}>
                  <Select
                    options={[
                      { label: "мужской", value: "man" },
                      { label: "женский", value: "woman" },
                    ]}
                  />
                </Form.Item>
                <Form.Item name={"birthday"} label={"Дата рождения"}>
                  <DatePicker format={"DD.MM.YYYY"} />
                </Form.Item>

                <Form.Item wrapperCol={isMobile ? undefined : { offset: 4, span: 14 }}>
                  <Space>
                    <Button type="primary" htmlType="submit">
                      Сохранить
                    </Button>
                    <Popconfirm
                      onConfirm={() =>
                        API.user
                          .delete()
                          .then(() => setUser(null))
                          .then(() => {
                            Cookies.remove("user");
                            Cookies.remove("token");
                          })
                      }
                      title={"Вы действительно хотите удалить аккаунт? \nВесь прогресс нельзя будет восстановить."}
                    >
                      <Button icon={<DeleteOutlined />} />
                    </Popconfirm>
                  </Space>
                </Form.Item>
              </Form>
            ),
          },
          {
            key: "1",
            label: "Сменить пароль",
            children: (
              <Form form={form} layout={"vertical"} onFinish={resetPassword}>
                <Form.Item
                  name={"oldPassword"}
                  label={"current password"}
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input.Password />
                </Form.Item>

                <Form.Item
                  name={"password"}
                  label={"new password"}
                  rules={[
                    {
                      required: true,
                    },
                    { min: 6, message: "Min length must be at least 6 characters" },
                  ]}
                >
                  <Input.Password />
                </Form.Item>

                <Form.Item
                  name={"passwordConfirm"}
                  dependencies={["password"]}
                  label={"confirm password: "}
                  rules={[
                    {
                      required: true,
                      message: "Please confirm your password!",
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue("password") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(new Error("The new password that you entered do not match!"));
                      },
                    }),
                  ]}
                >
                  <Input.Password />
                </Form.Item>

                <Button htmlType={"submit"}>Сохранить</Button>
              </Form>
            ),
          },
        ]}
      />
      {context}
    </div>
  );
};

export default Profile;
