import { Editable, ReactEditor, Slate, withReact } from "slate-react";
import React, { FC, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { CustomText, StatusType, TaskType } from "App.types";
import { HistoryEditor, withHistory } from "slate-history";
import { BaseEditor, BaseElement, createEditor, Transforms } from "slate";
import { GERMAN_VOICES, successMessage } from "App.constants";
import { Button, notification } from "antd";
import API from "Api";
import { getAudioUrl, getNonEnglishAudioUrl, isEqualText, withCustomLogic } from "App.helpers";
import { CloseOutlined, SoundFilled } from "@ant-design/icons";
import SentenceMaskedLeaf from "./SentenceLeaf";
import styles from "./SentenceTask.module.scss";
import { usePressEnter } from "./Helpers/usePressEnter";
import cx from "classnames";
import { SentenceTaskProps } from "./SentenceTask.type";
import { UserContext } from "App";
import TaskPanel from "Components/TaskPanel";

const initialValue = [{ children: [{ text: "" }] }];

export type CustomElement = { children: CustomText[] } & BaseElement;

declare module "slate" {
  export interface CustomTypes {
    Editor: ReactEditor & BaseEditor & HistoryEditor;
    Element: CustomElement;
    Text: CustomText;
  }
}

const SpellerTask: FC<SentenceTaskProps> = ({
  sentence,
  sentence: { id, text, translate, storyId },
  lesson,
  onTaskComplete,
  onNext,
  play,
  children,
  audio,
  setTry,
  showAnswer,
  setDictOpened,
  showGrammar,
}) => {
  const [status, setStatus] = useState<StatusType>(StatusType.Editing);
  const [activeLeaf, setActiveLeaf] = useState<number | null>(null);

  const editor = useMemo(() => withReact(withHistory(withCustomLogic(createEditor()))), []);
  const [notifyApi, contextHolder] = notification.useNotification({ placement: "bottom", bottom: 90, maxCount: 1 });

  const user = useContext(UserContext);

  const setInitialState = useCallback(
    (withFocus: boolean = true) => {
      editor.children.forEach(() => {
        Transforms.delete(editor, { at: [0] });
      });

      editor.children = [];

      Transforms.insertNodes(editor, showAnswer ? [{ children: [...text].map((s, idx) => ({ text: s, audioIdx: idx })) }] : initialValue, {
        select: withFocus,
      });
    },
    [editor, showAnswer, text],
  );

  // initial
  useEffect(() => {
    setActiveLeaf(null);
    setStatus(StatusType.Editing);
    setInitialState(true);
  }, [sentence.id, editor, setInitialState]);

  const onComplete = useCallback(
    (showMessage = true) => {
      setStatus(StatusType.Completed);

      showMessage && notifyApi.success(successMessage);
      onTaskComplete();
    },
    [notifyApi, onTaskComplete],
  );

  const renderLeaf = useCallback(
    (props: any) => (
      <SentenceMaskedLeaf
        underlined={props.leaf.audioIdx === (activeLeaf ?? -1)}
        showErrors={!["editing", "completed", "loading", "isRecording", ""].includes(status)}
        {...props}
      />
    ),
    [status, activeLeaf],
  );

  const onCheck = useCallback(
    async (userAnswer?: string, isHintPressed?: boolean) => {
      const userAnswerText =
        userAnswer ||
        editor.children[0]?.children
          .map((el: CustomText) => el.text)
          .join("")
          .trim();

      setInitialState();

      let hasError = false;
      [...userAnswerText].forEach((s, idx) => {
        const isWrong = !hasError && text[idx] !== s;

        Transforms.insertNodes(editor, {
          text: isWrong && isHintPressed && !hasError ? text[idx] : s,
          audioIdx: idx,
          status: isWrong ? "typo" : "",
        });

        if (isWrong) hasError = true;
      });

      if (text.length > userAnswerText.length) {
        if (isHintPressed && !hasError) {
          [...text].some((s, idx) => {
            const isWrong = s !== userAnswerText[idx];
            if (isWrong) Transforms.insertNodes(editor, { text: s, status: "hint" });
            return isWrong;
          });
        } else {
          Transforms.insertNodes(editor, { text: "", status: "missed" });
        }
      }

      if (isEqualText(userAnswerText, text)) {
        return onComplete();
      } else {
        if (isHintPressed) {
        } else {
          setTry();

          notifyApi.warning({
            message: "Есть ошибки",
            duration: 2,
          });
        }

        setStatus(StatusType.Error);
        return StatusType.Error;
      }
    },
    [editor, setInitialState, text, onComplete, setTry, notifyApi],
  );

  const onHintAnswer = () => {
    const text = editor.children[0]?.children
      ?.map((el: CustomText) => el.text)
      .join("")
      .trim();
    API.event.save({ text, type: "hint", task: TaskType.Speller, lesson: { id: lesson.id }, sentence });
    setTry(true);

    onCheck(text, true);
  };

  usePressEnter({ isCompleted: status === StatusType.Completed, onNext, onCheck });

  const onReset = () => {
    setInitialState(status === StatusType.Editing);
    ReactEditor.focus(editor);
  };

  const audios = useMemo(
    () =>
      [...text].map((s) =>
        user?.isEnglish
          ? text.length === 1
            ? new Audio(getAudioUrl(s, "uk"))
            : new Audio(getAudioUrl(s, "us"))
          : new Audio(getNonEnglishAudioUrl(s.toUpperCase(), GERMAN_VOICES[0], lesson.story.id)),
      ),
    [lesson.story.id, text, user?.isEnglish],
  );

  const playByLetters = () => {
    audios.forEach((a, idx) => {
      a.onended = () => {
        if (idx === audios.length - 1 && (status === StatusType.Completed || showAnswer)) {
          play?.().then(() => onComplete(false)) || audio?.play();
        } else {
          audios[idx + 1]?.play();
        }
        setActiveLeaf(idx + 1);
      };
      a.onplay = () => setActiveLeaf(idx);
    });

    audios[0].play();

    if (showAnswer && audio) {
      audio.onended = () => onComplete(false);
    }

    !showAnswer && ReactEditor.focus(editor);
  };

  const translateRef = useRef<HTMLDivElement>(null);

  translateRef.current?.scrollIntoView();

  return (
    <div className={styles.sentenceTask}>
      <div className={cx(styles.content, { [styles.content_hasChildren]: !!children })}>
        <div className={styles.children}>{children}</div>

        <div className={cx(styles.slate, { [styles.slate_alignCenter]: true })}>
          <div className={styles.slate_wrapper}>
            <form spellCheck="false">
              <Slate editor={editor} initialValue={initialValue}>
                <Editable
                  className={styles.textArea}
                  readOnly={status === "completed" || showAnswer}
                  onKeyDown={() => setStatus(StatusType.Editing)}
                  renderLeaf={renderLeaf}
                  //renderElement={(props) => <StoryElement isActive={activeSent === props.element.id} play={play} {...props} />}
                />
              </Slate>
            </form>
            {status !== "completed" && (
              <Button
                size={"small"}
                type={"text"}
                className={styles.btn__clear}
                icon={<CloseOutlined style={{ fontSize: 12 }} />}
                onClick={onReset}
              />
            )}
          </div>
          <div className={styles.translate}>{(status === StatusType.Completed || showAnswer) && translate}</div>
        </div>

        <div className={styles.bottom}>
          <Button type={"primary"} icon={<SoundFilled style={{ fontSize: 24 }} />} onClick={playByLetters} />
        </div>
      </div>

      <TaskPanel
        lessonId={lesson.id}
        task={TaskType.Speller}
        sentId={id}
        storyId={storyId}
        showGrammar={showGrammar}
        onCheck={onCheck}
        onNext={onNext}
        isCompleted={status === StatusType.Completed}
        onHint={onHintAnswer}
        setDictOpened={setDictOpened}
        audio={audio}
      ></TaskPanel>

      {contextHolder}
    </div>
  );
};

export default SpellerTask;
