import { SentenceType, TagType } from "App.types";
import React, { FC, useContext, useEffect, useMemo, useRef, useState } from "react";
import { Button, Collapse, Popover, Typography, Tabs, Flex, Space } from "antd";
import { SoundTwoTone } from "@ant-design/icons";
import cx from "classnames";
import { findIndex, isNumber, last } from "lodash";
import styles from "./StoryDialogSentence.module.scss";
import Dictionary from "Components/Dictionary";
import { getCompletedSlate } from "App.helpers";
import { useSuspenseQuery } from "@tanstack/react-query";
import API from "Api";
import { UserContext } from "App";
import { useTranscripts } from "Hooks/useTranscripts";
import { DEFAULT_VOICES } from "App.constants";
import { useIsBeginner } from "Hooks/useIsBeginner";

const { Text } = Typography;

const StoryDialogSentence: FC<{
  audio?: HTMLAudioElement;
  sentence?: SentenceType;
  active: boolean;
  activeWordIdx?: number;
  completedTagIdx?: number;
  onClick?: () => any;
  showText?: boolean;
  withTranslate?: boolean;
  showTranslate?: boolean;
  storyId?: string | number;
  hidden?: boolean;
  text?: string;
  voice?: string;
  tags?: TagType[];
  hideTooltip?: boolean;
}> = ({
  storyId,
  hidden,
  active,
  activeWordIdx,
  completedTagIdx,
  onClick,
  showText = true,
  withTranslate = false,
  showTranslate = false,
  sentence,
  audio,
  hideTooltip,
  text: initialText,
  tags: initialTags,
  voice: initialVoice,
  sentence: { voice: sentVoice, isLeft, tags: sentTags, translate, text: sentText, grammarLinks = [] } = {},
}) => {
  const [open, setOpen] = useState(false);
  const [isDictOpen, setDictOpen] = useState(false);
  const [activeIdx, setActiveIdx] = useState(-1);

  const user = useContext(UserContext);

  const { data: userProps } = useSuspenseQuery({
    queryKey: ["props", user?.id],
    queryFn: API.user.getProps,
    staleTime: Infinity,
  });

  const isBeginner = useIsBeginner();

  const isUserAWoman = useMemo(() => userProps.find((p) => p.name === "GENDR")?.value === "woman", [userProps]);

  const v1 = isUserAWoman ? DEFAULT_VOICES[0] : DEFAULT_VOICES[1];
  const v2 = isUserAWoman ? DEFAULT_VOICES[1] : DEFAULT_VOICES[0];

  const ref = useRef<HTMLDivElement>(null);
  const text = initialText || sentText;
  const tags = useMemo(() => initialTags || sentTags || [], [initialTags, sentTags]);
  const voice = initialVoice ?? sentVoice ?? (isLeft ? v1 : v2);

  const transcripts = useTranscripts({ sentence, voice });

  useEffect(() => {
    if (audio && active) {
      audio.ontimeupdate = ({ target: { currentTime } }: any) => {
        // @ts-ignore
        const wordIdx = findIndex(transcripts, (el) => el.end >= currentTime);
        setActiveIdx(wordIdx > -1 ? wordIdx : -1);
      };
    }
  }, [audio, transcripts, active]);

  const onPlay = () => {
    if (audio) {
      audio.play();
    } else {
      // @ts-ignore
      play(transcripts[0].start, last(transcripts)?.end, true);
    }
  };

  const wordTags = useMemo(() => getCompletedSlate(tags, text ?? ""), [tags, text]);

  const content = showTranslate ? (
    <i>{translate}</i>
  ) : (
    wordTags.map((el: any, idx) => (
      <span
        key={idx}
        // @ts-ignore
        className={cx(styles.word, {
          [styles.word_active]: active && (el.audioIdx <= activeIdx || (activeWordIdx && el.audioIdx <= activeWordIdx)),
          [styles.word_underlined]: active && isNumber(completedTagIdx) && el.idx > completedTagIdx,
        })}
      >
        {el.text}
      </span>
    ))
  );

  const items = grammarLinks.length
    ? [
        {
          label: "грамматика",
          children: (
            <Tabs
              size={"small"}
              items={grammarLinks.map((gram) => ({
                key: `${gram.id}`,
                label: gram.grammar.title,
                children: <div dangerouslySetInnerHTML={{ __html: gram.grammar.content }} />,
              }))}
            />
          ),
        },
      ]
    : [];

  return (
    <Popover
      open={open && !!text && !hideTooltip}
      className={styles.storyDialogSent}
      onOpenChange={(visible) => setOpen(visible && !isDictOpen)}
      content={
        <Flex className={styles.tooltip}>
          <div>{showText && <Text style={{ fontSize: 18, fontWeight: 500 }}>{showTranslate ? text : translate}</Text>}</div>
          <Space>
            <Button size={"middle"} onClick={onPlay} icon={<SoundTwoTone />} type={"text"} />

            <Button
              size={"middle"}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setDictOpen(true);
                setOpen(false);
              }}
            >
              {isBeginner ? "словарь" : "dictionary"}
            </Button>
            <Flex align={"center"}>
              <Collapse ghost accordion size={"small"} items={items} />
            </Flex>
          </Space>
        </Flex>
      }
      trigger={"click"}
    >
      <div
        onClick={onClick}
        ref={ref}
        className={cx(styles.storyDialogSent__text, {
          [styles.storyDialogSent__showTranslate]: showTranslate && !!text,
          [styles.storyDialogSent__textActive]: open || active,
          [styles.storyDialogSent__text_isLeft]: isLeft,
          [styles.storyDialogSent__hidden]: hidden,
        })}
      >
        {content}
        {withTranslate && <span className={styles.storyDialogSent__translate}> {translate}</span>}
      </div>
      <Dictionary storyId={storyId} isOpen={isDictOpen} toggle={setDictOpen} sentences={sentence} />
    </Popover>
  );
};
export default StoryDialogSentence;
