import { useMemo } from "react";
import { API_URL, AZURE_VOICES, DEFAULT_VOICES } from "App.constants";
import Cookies from "js-cookie";
import { SentenceType } from "App.types";

const useSentenceAudio = ({ sentence, disabled, voice }: { sentence: SentenceType | undefined; disabled?: boolean; voice?: string }) => {
  const token = Cookies.get("token");

  const v = voice ?? sentence?.voice ?? DEFAULT_VOICES[0];
  const style = sentence?.voiceStyle && (v.includes("DragonHD") || AZURE_VOICES.includes(v)) ? sentence.voiceStyle : "";

  return useMemo(
    () =>
      sentence?.text?.trim() && !disabled
        ? new Audio(`${API_URL}/speech/sent?sentId=${sentence.id}&voice=${v}&token=${token}&style=${style}`)
        : undefined,
    [sentence?.text, sentence?.id, disabled, v, token, style],
  );
};
export default useSentenceAudio;
