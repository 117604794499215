import { SentenceTaskProps } from "../SentenceTask.type";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { Form, Input, Modal } from "antd";
import { StatusType } from "App.types";
import { SendOutlined } from "@ant-design/icons";
import API from "Api";
import { ReactComponent as AlienSvg } from "./alien_head.svg";
import styles from "./Comversation.module.scss";
import RecordButton from "Components/RecordButton";
import useDeepgramAlt from "Hooks/useDeepgramAlt";
import { useSuspenseQuery } from "@tanstack/react-query";

function toArrayBuffer(buffer: any) {
  const arrayBuffer = new ArrayBuffer(buffer.length);
  const view = new Uint8Array(arrayBuffer);
  for (let i = 0; i < buffer.length; ++i) {
    view[i] = buffer[i];
  }
  return arrayBuffer;
}

const Conversation: FC<SentenceTaskProps> = ({ sentence }) => {
  const [showDialog, setShowDialog] = useState(false);
  const [firstMessage, setFirstMessage] = useState(false);

  const { linkedStoryId: storyId, linkedStory } = sentence;
  // const [status, setStatus] = useState<StatusType>();
  const [recordStatus, setRecordStatus] = useState<StatusType>(StatusType.Empty);

  const { data: topic } = useSuspenseQuery({
    queryKey: ["topic", linkedStory?.title],
    queryFn: () => (linkedStory ? API.conversation.getTopic(linkedStory.title) : undefined),
  });

  useEffect(() => {
    setShowDialog(!!topic);
  }, [topic]);

  const { start, isSpeaking, stop, results } = useDeepgramAlt({ model: "nova-2", setStatus: setRecordStatus });

  const [form] = Form.useForm<{ message: string }>();

  // const toggleStatus = () => {
  //   if (status === StatusType.IsRecording) {
  //     setStatus(StatusType.Empty);
  //   } else {
  //     setStatus(StatusType.IsRecording);
  //   }
  // };

  const audio = useMemo(() => new Audio(), []);

  const sendMessage = useCallback(
    (m: string) => {
      form.resetFields();

      API.conversation.send(m, storyId).then((data) => {
        const ab = toArrayBuffer(data.audio.data);
        const blob = new Blob([ab], { type: "audio/wav" });
        audio.src = window.URL.createObjectURL(blob);
        audio.play();
      });
    },
    [audio, form, storyId],
  );

  useEffect(() => {
    const message = results?.[0]?.transcript;
    if (message) {
      sendMessage(message);
    }
  }, [results, sendMessage]);

  const onRecordClick = useCallback(() => {
    if (recordStatus === StatusType.IsRecording) {
      stop();
    } else {
      start();
      if (!firstMessage) {
        sendMessage("");
        setFirstMessage(true);
      }
    }
  }, [firstMessage, recordStatus, sendMessage, start, stop]);

  return (
    <div className={styles.conversation}>
      <div className={styles.alien}>
        <AlienSvg />
      </div>
      <Form form={form}>
        <Form.Item name={"message"}>
          <Input.Search autoFocus enterButton={<SendOutlined />} onSearch={sendMessage} />
        </Form.Item>

        <RecordButton onClick={onRecordClick} isRecording={recordStatus === StatusType.IsRecording} isSpeaking={isSpeaking} />

        {/*<Button type={"primary"} onClick={toggleStatus}>*/}
        {/*  {status === StatusType.IsRecording ? "stop" : "start"}*/}
        {/*</Button>*/}
      </Form>

      <Modal
        title={"Продолжить диалог?"}
        okText={"Да"}
        cancelText={"Начать заново"}
        onCancel={() => topic && API.conversation.deleteTopic(topic?.id).then(() => setShowDialog(false))}
        open={showDialog}
        onOk={() => setShowDialog(false)}
      />
    </div>
  );
};

export default Conversation;
