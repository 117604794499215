import { Editable, ReactEditor, Slate, withReact } from "slate-react";
import React, { FC, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { CustomText, StatusType } from "App.types";
import { HistoryEditor, withHistory } from "slate-history";
import { BaseEditor, BaseElement, createEditor, Transforms } from "slate";
import { Button, Flex, Popconfirm, Rate, Segmented, Timeline } from "antd";
import API from "Api";
import cx from "classnames";
import { AudioOutlined, BulbTwoTone, CheckCircleOutlined, CloseOutlined, HeartFilled } from "@ant-design/icons";
import SentenceMaskedLeaf from "Components/SentenceTask/SentenceLeaf";
import { SentenceTaskProps } from "Components/SentenceTask/SentenceTask.type";
import { useAudioTranscript } from "Components/SentenceTask/Helpers/useAudioTranscript";
import TaskPanel from "Components/TaskPanel";
import styles from "Components/SentenceTask/SentenceTask.module.scss";
import { ReactComponent as KeyboardSvg } from "Assets/keyboard.svg";
import AudioRecorder from "./AudioRecorder";
import { NotifyContext } from "App";
import { keys } from "lodash";

const initialValue = [{ children: [{ text: "" }] }];

export type CustomElement = { children: CustomText[] } & BaseElement;

declare module "slate" {
  export interface CustomTypes {
    Editor: ReactEditor & BaseEditor & HistoryEditor;
    Element: CustomElement;
    Text: CustomText;
  }
}

const FreePractice: FC<SentenceTaskProps> = ({
  sentence,
  sentence: { id, phrases, linkedStory, linkedStoryId },
  activeType,
  lesson,
  onTaskComplete,
  onNext,
  children,
  audio,
  transcripts,
  showGrammar,
  setDictOpened,
}) => {
  const [status, setStatus] = useState<StatusType>(StatusType.Editing);
  const [activeLeaf, setActiveLeaf] = useState<number | null>(null);
  const [tries, setTries] = useState<number>(3);
  const [hints, setHints] = useState<number[]>([]);
  const [audioData, setAudioData] = useState<Blob>();

  const [questionStatuses, setQuestionStatuses] = useState<{ [key: number | string]: string }>({});
  const [loading, setLoading] = useState(false);
  const [mode, setMode] = useState<"speak" | "write">("speak");

  const editor = useMemo(() => withReact(withHistory(createEditor())), []);

  const notifyApi = useContext(NotifyContext);

  const { sentences: allSentences = [] } = linkedStory || {};
  const originalStoryId = useMemo(() => allSentences.find((s) => s.linkedStoryId)?.linkedStoryId, [allSentences]);
  const sentences = useMemo(() => allSentences.filter((s) => !s.linkedStoryId && s.text), [allSentences]);

  const setInitialState = useCallback(
    (withFocus: boolean = true) => {
      editor.children.forEach(() => {
        Transforms.delete(editor, { at: [0] });
      });

      editor.children = [];

      Transforms.insertNodes(editor, initialValue, { select: withFocus });
      if (withFocus) {
        try {
          ReactEditor.focus(editor);
        } catch (e) {}
      }
    },
    [editor],
  );

  // initial
  useEffect(() => {
    setActiveLeaf(null);
    setStatus(StatusType.Editing);
    setInitialState(true);
  }, [sentence.id, setInitialState, phrases]);

  // audio transcript
  useAudioTranscript({ setActiveLeaf, sentence, audio, transcripts });

  useEffect(() => {
    const items = keys(questionStatuses);
    if (items.every((k) => questionStatuses[k] === "green") && items.length === sentences.length) {
      onTaskComplete();
      setStatus(StatusType.Completed);
    }
  }, [onTaskComplete, questionStatuses, sentences.length]);

  useEffect(() => {
    if (!tries) {
      onTaskComplete();
      setStatus(StatusType.Completed);
    }
  }, [notifyApi, onTaskComplete, tries, status]);

  const renderLeaf = useCallback(
    (props: any) => (
      <SentenceMaskedLeaf
        underlined={props.leaf.audioIdx === (activeLeaf ?? -1)}
        showErrors={!["editing", "completed", "loading", "isRecording", ""].includes(status)}
        {...props}
      />
    ),
    [status, activeLeaf],
  );

  const onCheck = useCallback(async () => {
    let text = "";

    if (mode === "speak") {
      if (audioData) {
        const file = new File([audioData], "record");

        const form = new FormData();
        form.append("file", file);

        setLoading(true);
        text = await API.practice.speech(form);
      } else {
        notifyApi?.warning({ message: "Record the answer!" });
      }
    } else {
      text = editor.children[0]?.children
        ?.map((el: CustomText) => el.text)
        .join("")
        .trim();
    }

    if (!text) {
      setLoading(false);
      return notifyApi?.warning({ message: "The answer is empty!" });
    }

    setLoading(true);
    API.practice
      .check({ text, storyId: linkedStoryId ?? 0, originalStoryId })
      .then((data) => {
        setTries((prev) => prev - 1);
        const rightIdxs = `${data}`
          .split(/[ ,]/g)
          .map((t) => t.trim())
          .filter((el) => el);

        setQuestionStatuses((prev) => {
          rightIdxs.forEach((i) => {
            prev[+i - 1] = "green";
          });
          return { ...prev };
        });
      })
      .finally(() => setLoading(false));
  }, [audioData, editor.children, linkedStoryId, mode, notifyApi, originalStoryId]);

  const onReset = () => {
    setInitialState(status === StatusType.Editing);

    ReactEditor.focus(editor);
  };

  const timeline = useMemo(
    () =>
      sentences.map((s, idx) => ({
        children: (
          <>
            {s.text}{" "}
            {questionStatuses[idx] === "green" || status === StatusType.Completed ? (
              <div className={styles.hint}>{s.description}</div>
            ) : hints.includes(s.id) ? (
              <div className={styles.hint}>{s.translate}</div>
            ) : (
              <Popconfirm okText={"Yes"} cancelText={"No"} title={"Show hint?"} onConfirm={() => setHints((prev) => [...prev, s.id])}>
                <BulbTwoTone />
              </Popconfirm>
            )}
          </>
        ),
        color: questionStatuses[idx],
        dot: questionStatuses[idx] === "green" ? <CheckCircleOutlined className={styles.timeline__dot} /> : undefined,
      })),
    [sentences, questionStatuses, status, hints],
  );

  return (
    <div className={styles.sentenceTask}>
      <div className={cx(styles.content, { [styles.content_hasChildren]: !!children })}>
        <div className={styles.children}>{children}</div>

        <div className={cx(styles.slate, {})}>
          <Timeline className={styles.timeline} items={timeline} />

          {mode === "speak" && <AudioRecorder setAudioData={setAudioData} />}

          <div className={styles.slate_wrapper} style={{ display: mode === "speak" ? "none" : undefined }}>
            <form spellCheck="false">
              <Slate editor={editor} initialValue={initialValue}>
                <Editable
                  className={styles.textArea}
                  readOnly={status === "completed"}
                  onKeyDown={() => setStatus(StatusType.Editing)}
                  renderLeaf={renderLeaf}
                  //renderElement={(props) => <StoryElement isActive={activeSent === props.element.id} play={play} {...props} />}
                />
              </Slate>
            </form>

            {status !== "completed" && (
              <Button
                size={"small"}
                type={"text"}
                className={styles.btn__clear}
                icon={<CloseOutlined style={{ fontSize: 12 }} />}
                onClick={onReset}
              />
            )}
          </div>

          <div className={styles.translate}>
            <Flex justify={"space-between"}>
              <Rate character={<HeartFilled />} count={3} value={tries} disabled />
              <Segmented
                value={mode}
                onChange={setMode}
                options={[
                  {
                    label: (
                      <Flex>
                        <KeyboardSvg height={30} width={30} />
                      </Flex>
                    ),
                    value: "write",
                  },
                  {
                    label: <AudioOutlined style={{ fontSize: 22, height: 28 }} />,
                    value: "speak",
                  },
                ]}
              />
            </Flex>
          </div>
        </div>

        <div className={styles.bottom}></div>
      </div>

      <TaskPanel
        isLoading={loading}
        onCheck={onCheck}
        lessonId={lesson.id}
        task={activeType}
        sentId={id}
        storyId={originalStoryId}
        isCompleted={status === StatusType.Completed}
        setDictOpened={setDictOpened}
        onNext={onNext}
        audio={audio}
        showGrammar={showGrammar}
      ></TaskPanel>
    </div>
  );
};

export default FreePractice;
