import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useRef } from "react";
import { findIndex, last } from "lodash";
import { SentenceType, StatusType, StoryType } from "App.types";
import { STATIC_URL } from "App.constants";
import { encodeS3URI } from "App.helpers";

type Props = {
  sentences: SentenceType[];
  story: StoryType;
  setAudioStatus: Dispatch<SetStateAction<StatusType>>;
  setActiveWordIdx: Dispatch<SetStateAction<number>>;
  setActiveSentIdx: Dispatch<SetStateAction<number>>;
  onFinish: Function;
};

export const useNativeAudio = ({ sentences, story, setAudioStatus, onFinish, setActiveSentIdx, setActiveWordIdx }: Props) => {
  const audio = useMemo(
    () => (story && story.audio ? new Audio(`${STATIC_URL}/stories/${encodeS3URI(story.title)}/audio.mp3`) : undefined),
    [story],
  );

  useEffect(() => {
    audio?.addEventListener("play", () => setAudioStatus(StatusType.isPlaying));
    audio?.addEventListener("pause", () => setAudioStatus(StatusType.Empty));
    audio?.addEventListener("ended", () => onFinish());

    return () => audio?.pause();
  }, [audio, setAudioStatus, onFinish]);

  const timeListener = useRef<any>(null);

  const pause = useCallback(() => audio?.pause(), [audio]);

  useEffect(() => {
    if (!audio) return;

    audio.removeEventListener("timeupdate", timeListener.current);

    timeListener.current = ({ target: { currentTime } }: any) => {
      let currentSentIdx = sentences.findIndex(
        ({ transcripts }) => currentTime >= transcripts[0]?.start && currentTime < (last(transcripts)?.end || 0),
      );

      if (currentSentIdx === -1) {
        currentSentIdx = sentences.findIndex(({ transcripts }) => transcripts[0]?.start >= currentTime);
      }

      if (currentSentIdx > -1) {
        const wordIdx = findIndex(sentences[currentSentIdx].transcripts, (el) => el.end >= currentTime);
        setActiveWordIdx(wordIdx > -1 ? wordIdx : -1);
        setActiveSentIdx(currentSentIdx);
      }
    };

    audio.addEventListener("timeupdate", timeListener.current);
  }, [audio, sentences, setActiveSentIdx, setActiveWordIdx]);

  const play = useCallback(() => audio?.play(), [audio]);

  return { play, pause };
};
