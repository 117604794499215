export enum TaskType {
  Listen = "listen",
  StoryListen = "storyListen",
  Read = "read",
  StoryRead = "storyRead",
  ReadByWord = "readByWord",
  Repeat = "repeat",
  Scroller = "scroller",
  Intro = "intro",
  ActiveDialog = "activeDialog",
  VoiceTranslate = "voiceTranslate",
  Masked = "masked",
  StoryMasked = "storyMasked",
  StorySelect = "storySelect",
  Translate = "translate",
  Collect = "collect",
  AudioCollect = "audioCollect",
  Dictate = "dictate",
  Select = "select",
  MultiSelect = "multiSelect",
  MultiSelectReverse = "multiSelectReverse",
  PictureSelect = "pictureSelect",
  ReverseSelect = "reverseSelect",
  AudioSelect = "audioSelect",
  Reverse = "reverse",
  Convert = "convert",
  Match = "match",
  Speller = "speller",
  SpellerListen = "spellerListen",
  SpellerSelect = "spellerSelect",
  SpellerCollect = "spellerCollect",
  Constructor = "constructor",
  MaskedDragDrop = "maskedDragDrop",
  Grammar = "grammar",
  StoryDragDrop = "storyDragDrop",
  Video = "video",
  Dictionary = "dictionary",
  FromStory = "fromStory",
  FeedbackMultiple = "feedbackMultiple",
  FeedbackSelect = "feedbackSelect",
  FeedbackText = "feedbackText",
  FeedbackCalendar = "feedbackCalendar",
  OneButton = "oneButton",
  VideoSelect = "videoSelect",
  VideoDragDrop = "videoDragDrop",
  Conversation = "conversation",
  FreePractice = "freePractice",
}

export enum StatusType {
  Error = "error",
  Completed = "completed",
  Editing = "editing",
  IsRecording = "isRecording",
  isPlaying = "isPlaying",
  Loading = "loading",
  Order = "order",
  Empty = "empty",
}

type CourseType = {
  id: number;
  isActive: boolean;
  title: string;
  level?: number;
  translate?: string;
  description?: string;
  grammar?: GrammarType;
  grammarId?: number;
  picture?: string;
};

type StoryType = {
  id: number;
  title: string;
  sentCount: number;
  wordCount?: number;
  level: number;
  type: "book" | "story" | "exercise" | "card" | "dialog" | "video";
  sentences: SentenceType[];
  author?: string;
  description?: string;
  translate?: string;
  picture?: string;
  audio?: string;
};

type TagType = {
  id: number;
  word: string;
  pos?: string;
  lemma: string;
  isMasked?: boolean;
  isHint?: boolean;
};

type AlternativeType = {
  id: number;
  text: string;
  translate?: string;
  media?: string;
  wrong?: boolean;
  tags?: TagType[];
};

type GrammarType = {
  id: number;
  title: string;
  content: string;
  isGroup: boolean;
  children?: GrammarType[];
  parent?: GrammarType;
  tags?: string[];
};

type GrammarLinkType = {
  id: number;
  grammar: GrammarType;
  positionsIdx?: number[];
  sentence: { id: number };
};

type MarkerType =
  | "random"
  | "noTranslate"
  | "noText"
  | "subtitles"
  | "subtitlesAfter"
  | "fireworks"
  | "showGrammar"
  | "anySelect"
  | "conditional"
  | "template"
  | "easySpeak"
  | "onlyAudio"
  | "hideMaskedHint";

type SentenceType = {
  id: number;
  text: string;
  translate: string;
  description?: string;
  speak?: string;
  hint?: string;
  info?: string;
  order: number;
  page: number;
  reverseIdx?: number;
  wrap: boolean;
  storyId: number;
  linkedStory?: StoryType;
  linkedStoryId?: number;
  mediaStory?: StoryType;
  mediaStoryId?: number;
  optionStory?: StoryType;
  optionStoryId?: number;
  tags: TagType[];
  task?: TaskType;
  tasks: TaskType[];
  markers: MarkerType[];
  // isRandom?: boolean;
  isCard?: boolean;
  phrases: { id: number; text: string; positionsIdx: number[] }[];
  alternatives: AlternativeType[];
  transcripts: TranscriptType[];
  wordGroup?: WordGroupType;
  reverseGroup?: WordGroupType;
  grammarLinks: GrammarLinkType[];
  options?: number[];
  multi?: number[];
  grammar?: GrammarType;
  isLeft?: boolean;
  voice?: string;
  voiceStyle?: string;
  media?: string;
  medias: MediaType[];
};

type DictionaryType = {
  word: string;
  pos: string;
  tag: string;
  translates: string[];
  rank?: number;
  levels?: string[];
  isPast?: boolean;
};

type TagId = number;

type FullWordInfoType = {
  id: TagId;
  word: string;
  pos: string;
  tag: string;
  translates: string[];
  rank?: number;
  levels?: string[];
  lemma?: string;
};

type ProgressType = {
  id: number;
  lesson: { id: number };
  sentence: { id: number };
  percent: number;
  tries: number;
  page?: number;
  score?: number;
  status?: string;
  answer?: string;
  type?: string;
  stars?: number;
};

type VocabularyType = {
  word: string;
  status: string;
  timestamp: string;
};

type EventType = {
  id: number;
  sentence: { id: number };
  lesson: { id: number };
  text: string;
  type: string;
  task: string;
  timestamp: string;
};

type TranscriptType = {
  sent?: number;
  text: string;
  word?: string;
  start: number;
  end: number;
};

type WordGroupType = { id: number; title: string; words: string[]; withForms?: boolean; system?: boolean };

type VerbFormType = {
  base: string;
  v2: string;
  v3: string;
  es: string;
  ing: string;
  v6?: string;
  v7?: string;
  v8?: string;
  v9?: string;
  v10?: string;
};

type LessonType = {
  id: number;
  isActive: boolean;
  optional?: boolean;
  level: number;
  description: string;
  checkTypos: boolean;
  story: StoryType;
  storyId: number;
  order: number;
  selectLimit?: number;
  spellerHint?: string;
  grammar?: GrammarType;
  type: "exercise" | "speech" | "story" | "module" | "grammar";
  moduleId?: number;
};

type CommentType = {
  id: number;
  sentence: { id: number };
  user: { picture: string; name: string };
  text: string;
  timestamp: string;
};

type PhraseType = { id: number; text: string; positionsIdx: number[] };

type CustomText = {
  word: string;
  text: string;
  lemma: string;
  answer: string;
  id?: number;
  status: string;
  idx: number;
  isMasked?: boolean;
  pos?: string;
};

type FeedbackType = {
  id: number;
  question: string;
  answer?: string;
  type: string;
  meta?: string;
  lessonId?: number;
  sentenceId?: number;
  storyId?: number;
  task?: string;
  rate?: number;
};

type MediaType = {
  id: number;
  title: string;
  url: string;
  start?: number;
  end?: number;
  layout?: "vertical" | "horizontal" | undefined;
};

type UserType = {
  id: number;
  level: number;
  activeCourse: number;
  name: string;
  email: string;
  picture: string;
  birthday: string;
  lang: string;
  isEnglish: boolean;
  isAdmin: boolean;
};

type UserProp = {
  id: number;
  name: string;
  value: string;
};

export type {
  StoryType,
  SentenceType,
  DictionaryType,
  TagType,
  FullWordInfoType,
  ProgressType,
  EventType,
  AlternativeType,
  TranscriptType,
  WordGroupType,
  VerbFormType,
  LessonType,
  CommentType,
  PhraseType,
  CustomText,
  CourseType,
  GrammarType,
  MediaType,
  UserType,
  MarkerType,
  VocabularyType,
  FeedbackType,
  UserProp,
};
