import { FC, useEffect, useRef, useState } from "react";
import RecordButton from "Components/RecordButton";
import { Flex } from "antd";
import { isMobile } from "react-device-detect";

const AudioRecorder: FC<{ setAudioData: Function }> = ({ setAudioData }) => {
  const [isRecording, setIsRecording] = useState(false);
  const [recordedUrl, setRecordedUrl] = useState<string>();

  const mediaStream = useRef<MediaStream>();
  const mediaRecorder = useRef<MediaRecorder>();
  const chunks = useRef<Blob[]>([]);

  const audioRef = useRef<HTMLAudioElement>(null);

  useEffect(() => {
    if (recordedUrl) {
      audioRef.current?.play();
    }
  }, [recordedUrl]);

  const startRecording = async () => {
    setIsRecording(true);

    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      mediaStream.current = stream;
      mediaRecorder.current = new MediaRecorder(stream);
      mediaRecorder.current.ondataavailable = (e) => {
        if (e.data.size > 0) {
          chunks.current.push(e.data);
        }
      };
      mediaRecorder.current.onstop = () => {
        const recordedBlob = new Blob(chunks.current, { type: "audio/mpeg" });
        setAudioData(recordedBlob);
        const url = URL.createObjectURL(recordedBlob);
        setRecordedUrl(url);

        chunks.current = [];
      };
      mediaRecorder.current.start();
    } catch (error) {
      setIsRecording(false);
      console.error("Error accessing microphone:", error);
    }
  };
  const stopRecording = () => {
    setIsRecording(false);

    if (mediaRecorder.current && mediaRecorder.current.state === "recording") {
      mediaRecorder.current.stop();
    }
    if (mediaStream.current) {
      mediaStream.current.getTracks().forEach((track) => {
        track.stop();
      });
    }
  };

  const startStop = () => {
    if (isRecording) {
      stopRecording();
    } else {
      startRecording();
    }
  };

  return (
    <Flex align={"center"} vertical={isMobile}>
      <RecordButton isRecording={isRecording} onClick={startStop} />
      <audio ref={audioRef} controls src={recordedUrl} style={{ height: 40, width: isMobile ? "100%" : "" }} />
    </Flex>
  );
};
export default AudioRecorder;
