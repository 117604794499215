import { delay, flatten, last, round } from "lodash";
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { SkipTags } from "App.constants";
import { CustomText, SentenceType, StatusType, TaskType } from "App.types";
import Dictionary from "Components/Dictionary";
import GrammarPanel from "Components/GrammarPanel";
import { getCompletedSlate, withCustomLogic } from "App.helpers";
import useDeepgram from "Hooks/useDeepgram";
import { useSpeechCheck } from "Hooks/useSpeechCheck";
import Slider from "react-slick";
import styles from "./IntroLesson.module.scss";
import { Button, Flex, Popconfirm, Progress, Space } from "antd";
import { RollbackOutlined } from "@ant-design/icons";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import HintDrawer from "Components/HintDrawer";
import { useQueryClient, useSuspenseQuery } from "@tanstack/react-query";
import API from "Api";
import { useNavigate, useParams } from "react-router-dom";
import ScrollerTaskRow from "Components/SentenceTask/ScrollerTask/ScrollerTaskRow";
import useSentenceAudio from "Hooks/useSentenceAudio";
import ButtonClose from "Components/ButtonClose";
import IntroLessonTaskTranslate from "./IntroLessonTaskTranslate";
import { useOnCheck } from "Components/SentenceTask/Helpers/useOnCheck";
import { usePressEnter } from "Components/SentenceTask/Helpers/usePressEnter";
import { BaseEditor, BaseElement, createEditor, Transforms } from "slate";
import { ReactEditor, withReact } from "slate-react";
import { HistoryEditor, withHistory } from "slate-history";
import CompleteWindow from "Components/CompleteWindow";
import useProgress from "Hooks/useProgress";
import ReportFeedback from "Components/ReportFeedback";
import RecordButton from "Components/RecordButton";
import SentenceTaskHelpButton from "Components/SentenceTask/SentenceTaskHelpButton";
import TaskPanelBasic from "Components/TaskPanelBasic";
import { useIsBeginner } from "Hooks/useIsBeginner";

export type CustomElement = { children: CustomText[] } & BaseElement;

declare module "slate" {
  export interface CustomTypes {
    Editor: ReactEditor & BaseEditor & HistoryEditor;
    Element: CustomElement;
    Text: CustomText;
  }
}

const IntroLessonNew: FC<{}> = () => {
  const [isDictOpened, setDictOpened] = useState(false);
  const [showGrammar, setShowGrammar] = useState(false);
  const [activeIdx, setActiveIdx] = useState(0);
  const [status, setStatus] = useState<StatusType>(StatusType.Empty);
  const [lessonStatus, setLessonStatus] = useState<StatusType>(StatusType.Empty);
  const [audioStatus, setAudioStatus] = useState<StatusType>(StatusType.Empty);
  const [completedTagIdx, setCompletedTagIdx] = useState<number>(-1);
  const [hintDrawerStatus, setHintDrawerStatus] = useState<"closed" | "active" | "">();

  const { id = "", course, storyId = "" } = useParams();

  const isBeginner = useIsBeginner();

  const { data: lesson } = useSuspenseQuery({
    staleTime: Infinity,
    queryKey: ["lesson", id],
    queryFn: () => API.lesson.get(id),
  });

  const { data: sentences } = useSuspenseQuery({
    staleTime: Infinity,
    queryKey: ["sentences", storyId],
    queryFn: () => API.sentence.getListByStoryId(storyId),
  });

  const editor = useMemo(() => withReact(withHistory(withCustomLogic(createEditor()))), []);

  const sliderRef = useRef<Slider>(null);

  const queryClient = useQueryClient();

  const onRepeat = useCallback(() => {
    setCompletedTagIdx(-1);

    API.progress.delete(id).then(() => {
      queryClient.invalidateQueries({ queryKey: ["progress", +id] });
    });

    queryClient.setQueryData(["progress", +id], []);

    setLessonStatus(StatusType.Empty);
  }, [id, queryClient]);

  const { progress, contextHolder } = useProgress({ lesson: lesson, onRepeat });

  const completedCards = useMemo(() => sentences?.filter((s) => progress?.some((pr) => pr.sentence.id === s.id)), [progress, sentences]);

  const tasksToComplete = useMemo<any>(() => {
    return sentences.filter((s) => !completedCards?.some((completed) => completed?.id === s?.id));
  }, [sentences, completedCards]);

  const allTasks = useMemo(
    () => [
      ...completedCards.map((s) => ({ ...s, completed: true })),
      ...tasksToComplete.map((s: SentenceType) => ({ ...s, task: s.tasks[0] })),
    ],
    [completedCards, tasksToComplete],
  );

  useEffect(() => {
    const nextActiveIdx = allTasks.findIndex((s) => !s.completed);
    if (nextActiveIdx > -1) {
      setActiveIdx(nextActiveIdx);
    }
  }, [allTasks]);

  const activeSentence = useMemo<SentenceType>(() => allTasks[activeIdx], [allTasks, activeIdx]);

  const { start, stop, reset, words, isSpeaking, transcript } = useDeepgram({
    tags: flatten(sentences.map((s) => s.tags)),
    setStatus: setAudioStatus,
    smart_format: true,
    interim_results: true,
  });

  useEffect(() => {
    if (allTasks[activeIdx]?.hint) {
      setHintDrawerStatus((prev) => (prev === "closed" ? prev : "active"));
    } else {
      setHintDrawerStatus("");
    }
  }, [activeIdx, allTasks]);

  const setEditorText = useCallback(
    (text: string = "") => {
      editor.children.forEach(() => {
        Transforms.delete(editor, { at: [0] });
      });

      editor.children = [];

      Transforms.insertNodes(editor, [{ children: [{ text }] }], { select: true });
    },
    [editor],
  );

  // next
  const onNextSentence = useCallback(() => {
    setStatus(StatusType.Empty);

    setCompletedTagIdx(-1);

    setEditorText();
    sliderRef.current?.slickNext();

    if (activeIdx >= allTasks.length - 1) {
      stop();

      API.progress.save({
        lesson: { id: +id },
        percent: 100,
        status: "completed",
      });

      return setLessonStatus(StatusType.Completed);
    } else {
      setActiveIdx((prev) => prev + 1);
    }
  }, [activeIdx, allTasks.length, id, setEditorText, stop]);

  const audio = useSentenceAudio({ sentence: activeSentence });

  const onTaskComplete = useCallback(() => {
    reset();

    setStatus(StatusType.Completed);
    const result = {
      sentence: { id: activeSentence?.id || 0 },
      lesson: { id: +id },
      type: activeSentence?.task || TaskType.Translate,
      tries: 1,
    };
    return API.progress.save(result);
  }, [activeSentence?.id, activeSentence?.task, id, reset]);

  // useEffect(() => {
  //   if (activeSentence.task === TaskType.Listen && audio) {
  //     audio.addEventListener("ended", onTaskComplete);
  //   }
  // }, [activeSentence.task, audio, onTaskComplete]);

  const filteredRightTags = useMemo(
    () =>
      activeSentence &&
      getCompletedSlate(activeSentence?.tags || [], activeSentence.text).filter((el) => el.word && !SkipTags.includes(el.word)),
    [activeSentence],
  );

  useSpeechCheck({
    completedTagIdx,
    sentence: activeSentence,
    status,
    setCompletedTagIdx,
    textFromSpeech: words.join(" "),
    filteredRightTags,
  });

  useEffect(() => {
    if (completedTagIdx >= last<any>(filteredRightTags)?.idx) {
      onTaskComplete();
    }
  }, [completedTagIdx, filteredRightTags, activeSentence.id, onNextSentence, onTaskComplete]);

  const onRecordClick = useCallback(() => {
    if (audioStatus === StatusType.IsRecording) {
      stop();
    } else {
      setCompletedTagIdx(-1);
      // setCompletedSpeech("");
      setAudioStatus(StatusType.Loading);
      start();
    }
  }, [audioStatus, start, stop]);

  const onCheck = useOnCheck({
    editor,
    activeType: TaskType.Translate,
    text: activeSentence.text,
    tags: activeSentence.tags,
    id: activeSentence.id,
    lesson,
    alternatives: activeSentence.alternatives,
    onComplete: onTaskComplete,
    setStatus,
  });

  const onCheckForSpeech = useOnCheck({
    editor,
    activeType: TaskType.Translate,
    text: activeSentence.text,
    tags: activeSentence.tags,
    id: activeSentence.id,
    lesson,
    alternatives: activeSentence.alternatives,
    setStatus,
  });

  useEffect(() => {
    if (status === StatusType.Completed) {
      timeoutRef.current = delay(() => onNextSentence(), 1000);
    }
    return () => clearTimeout(timeoutRef.current);
  }, [onNextSentence, status]);

  usePressEnter({
    isCompleted: status === StatusType.Completed,
    onNext: () => {
      clearTimeout(timeoutRef.current);
      onNextSentence();
    },
    onCheck,
  });

  const percent = useMemo(() => {
    const tasksCount = allTasks.length;
    return round((100 / tasksCount) * activeIdx, 2);
  }, [activeIdx, allTasks.length]);

  const navigate = useNavigate();
  const timeoutRef = useRef<number>();

  const onClear = useCallback(() => {
    reset();
    setEditorText();
  }, [reset, setEditorText]);

  useEffect(() => {
    if (transcript) {
      setEditorText(transcript);
      onCheckForSpeech(transcript);
    }
  }, [onCheckForSpeech, setEditorText, transcript]);

  const resetStatus = useCallback(() => setStatus(StatusType.Editing), []);

  return (
    <div className={styles.introLesson}>
      {lessonStatus === "completed" ? (
        <CompleteWindow onRepeat={onRepeat} lessonId={+id} course={course} />
      ) : (
        <>
          <div className={styles.header}>
            <Flex>
              <Progress percent={round(percent)} showInfo={false} />
              <ButtonClose path={course ? `/course/${course}` : "/"} />
            </Flex>
          </div>

          <Popconfirm
            okText={"Yes"}
            cancelText={"No"}
            title={"Переходим в урок вместо того, чтобы выполнить это экспресс-задание?"}
            onConfirm={() => navigate(`/course/${course}/lessons/exercise/${id}`)}
          >
            <Button icon={<RollbackOutlined />} type={"link"}>
              Пройти этот урок
            </Button>
          </Popconfirm>

          <Slider infinite={false} centerPadding={"10px"} ref={sliderRef} centerMode vertical slidesToShow={3} slidesToScroll={1}>
            {allTasks.map((t: any, idx: number) =>
              idx === activeIdx ? (
                <IntroLessonTaskTranslate
                  key={idx}
                  audio={idx === activeIdx ? audio : undefined}
                  sentence={t}
                  onClear={onClear}
                  editor={editor}
                  status={status}
                  resetStatus={resetStatus}
                />
              ) : (
                <ScrollerTaskRow
                  key={idx}
                  audio={idx === activeIdx ? audio : undefined}
                  sentence={t}
                  activeType={TaskType.Translate}
                  lesson={lesson}
                  isCompleted={idx < activeIdx}
                  alignCenter={true}
                  completedTagIdx={completedTagIdx}
                />
              ),
            )}
            <div></div>
            <div></div>
          </Slider>
        </>
      )}

      {lessonStatus !== StatusType.Completed && (
        <TaskPanelBasic>
          <ReportFeedback lessonId={lesson.id} sentId={activeSentence?.id} task={activeSentence?.task} storyId={storyId} />

          <Space>
            <Button shape={"round"} type={"primary"} onClick={() => onCheck()}>
              {isBeginner ? "проверить" : "check"}
            </Button>
            <RecordButton
              onClick={onRecordClick}
              isRecording={audioStatus === "isRecording"}
              loading={audioStatus === "loading"}
              isSpeaking={isSpeaking}
            />
          </Space>

          <SentenceTaskHelpButton storyId={storyId} openDictionary={setDictOpened} showGrammar={setShowGrammar} />
        </TaskPanelBasic>
      )}

      <HintDrawer text={allTasks[activeIdx]?.hint} open={hintDrawerStatus === "active"} onClose={() => setHintDrawerStatus("closed")} />
      <Dictionary storyId={lesson.storyId} isOpen={isDictOpened} sentences={sentences} toggle={setDictOpened} />
      <GrammarPanel isOpen={showGrammar} grammar={lesson?.grammar} toggle={setShowGrammar} />
      {contextHolder}
    </div>
  );
};

export default IntroLessonNew;
