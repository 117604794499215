import { useEffect } from "react";
import { STATIC_URL } from "App.constants";
import { encodeS3URI } from "App.helpers";

const useGrammarAudio = (content: string) => {
  useEffect(() => {
    const voiceSpans = document.getElementsByClassName("voice") ?? [];
    const elements: any[] = [];

    for (const el of voiceSpans) {
      // @ts-ignore
      const text = (el as Element).innerText.trim();
      const voice = (el as Element).getAttribute("voice");

      const audio = new Audio(`${STATIC_URL}/sentences/${encodeS3URI(text)}/${voice}_.mp3`);

      const fn = () => audio.play();
      el.addEventListener("click", fn);
      elements.push({ el, fn });
    }

    const spans = document.getElementsByClassName("audio");
    Array.from(spans).forEach((el) => {
      const text = el.textContent?.trim();

      let audio: HTMLMediaElement | any = text && document.getElementById(text);
      if (!audio && text) {
        audio = new Audio(`${STATIC_URL}/sentences/${encodeS3URI(text)}/${"US Woman Ruth"}_.mp3`);
      }
      audio && el.addEventListener("click", () => audio.play());
    });

    return () => {
      elements.forEach(({ el, fn }) => el.removeEventListener("click", fn));
    };
  }, [content]);
};

export default useGrammarAudio;
