import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { delay, flatten } from "lodash";
import { Button, Flex, Popconfirm } from "antd";
import { StatusType, TaskType } from "App.types";
import Dictionary from "Components/Dictionary";
import StoryDialogSentence from "Components/StoryDialogSentence";
import { Element, scroller } from "react-scroll";
import cx from "classnames";
import TaskPanel from "Components/TaskPanel";
import { isMobile } from "react-device-detect";
import styles from "./DialogTask.module.scss";
import { SentenceTaskProps } from "../SentenceTask.type";
import DialogTaskOptions from "./DialogTaskOptions";
import useDeepgram from "Hooks/useDeepgram";
import DialogTaskTranslate from "./DialogTaskTranslate";
import DialogTaskRead from "./DialogTaskRead";
import { useLocation } from "react-router-dom";
import HintDrawer from "Components/HintDrawer";
import useTemplatedSentences from "../useTemplatedSentences";
import useSentencesAudios from "Hooks/useSentencesAudios";
import { ReloadOutlined } from "@ant-design/icons";

const DialogTask: FC<SentenceTaskProps> = ({ autoPlay = true, lesson, onTaskComplete, onNext, sentence: taskSentence, showGrammar }) => {
  const [status, setStatus] = useState<StatusType>(StatusType.Empty);
  const [audioStatus, setAudioStatus] = useState<StatusType>(StatusType.Empty);
  const [activeSentIdx, setActiveSentIdx] = useState<number>(-1);
  const [isDictOpened, setDictOpened] = useState(false);
  const [hintDrawerStatus, setHintDrawerStatus] = useState<"closed" | "active" | "">();

  const location = useLocation();

  const isReview = useMemo(() => location.pathname.includes("/review"), [location]);

  const { sentences: defSentences = [], id: storyId } = taskSentence.linkedStory || {};

  const sentences = useTemplatedSentences(defSentences);

  useEffect(() => {
    if (activeSentIdx >= 0 && activeSentIdx < sentences.length) {
      scroller.scrollTo(`${activeSentIdx}`, { smooth: true, isDynamic: true });
    }
  }, [activeSentIdx, sentences.length]);

  useEffect(() => {
    if (status === StatusType.Empty && autoPlay) setActiveSentIdx(0);
  }, [autoPlay, status]);

  const allTags = useMemo(() => flatten(sentences.map((s) => s.tags)), [sentences]);

  const { start, stop, reset, transcript: textFromSpeech, isSpeaking } = useDeepgram({ setStatus: setAudioStatus, tags: allTags });

  useEffect(() => {
    if (![TaskType.Read, TaskType.VoiceTranslate, TaskType.Translate].includes(sentences[activeSentIdx]?.tasks[0])) {
      stop();
    }
  }, [activeSentIdx, sentences, stop]);

  const audios = useSentencesAudios({ sentences });

  useEffect(() => {
    audios.forEach((audio, idx) => {
      audio.onended = ({ type }) =>
        delay(() => {
          if (type === "ended" && (!sentences[idx].tasks.length || sentences[idx].tasks[0] === TaskType.Listen))
            setActiveSentIdx((prev) => prev + 1);
        }, 500);
    });
  }, [audios, sentences]);

  const activeAudio = useMemo(() => audios?.[activeSentIdx], [audios, activeSentIdx]);
  const onNextRef = useRef<any>(null);

  useEffect(() => {
    if (
      sentences[activeSentIdx] &&
      (sentences[activeSentIdx].tasks.length === 0 || sentences[activeSentIdx].tasks[0] === TaskType.Listen)
    ) {
      if (sentences[activeSentIdx].text) {
        activeAudio?.play();
      } else {
        clearTimeout(onNextRef.current);
        onNextRef.current = delay(() => setActiveSentIdx((prev) => prev + 1), 1000);
      }
    }
  }, [activeAudio, activeSentIdx, sentences]);

  useEffect(() => {
    if (sentences.length && activeSentIdx >= sentences.length && status !== StatusType.Completed) {
      setStatus(StatusType.Completed);
      onTaskComplete();
    }
  }, [activeSentIdx, onTaskComplete, sentences.length, status]);

  const onRecordClick = useCallback(() => {
    if (audioStatus === StatusType.IsRecording) {
      stop();
    } else {
      start();
    }
  }, [audioStatus, start, stop]);

  const availableSentences = useMemo(
    () => (isReview ? sentences : sentences.slice(0, activeSentIdx + 1)),
    [sentences, activeSentIdx, isReview],
  );

  useEffect(() => {
    if (availableSentences[activeSentIdx]?.hint) {
      setHintDrawerStatus("active");
    } else {
      setHintDrawerStatus(undefined);
    }
  }, [activeSentIdx, availableSentences]);

  const onSuccess = useCallback(() => delay(() => setActiveSentIdx((prev) => prev + 1), 500), []);

  return (
    <div className={cx(styles.storyTask, { [styles.storyTask_mobile]: isMobile })}>
      <div className={styles.storyTask__text}>
        <Flex vertical style={{ maxWidth: 680 }} gap={5}>
          {availableSentences.map((sentence, idx) => (
            <Element name={`${idx}`} key={sentence.id}>
              <Flex justify={sentence.isLeft ? "flex-start" : "flex-end"}>
                <div style={{ maxWidth: "90%" }}>
                  {![TaskType.MultiSelect, TaskType.VoiceTranslate, TaskType.Read, TaskType.Translate].includes(sentence.tasks[0]) ? (
                    <StoryDialogSentence
                      showTranslate={!sentence.text}
                      storyId={storyId}
                      hidden={activeSentIdx < idx && !isReview}
                      active={idx === activeSentIdx}
                      audio={audios?.[idx]}
                      sentence={sentence}
                    />
                  ) : sentence.tasks.includes(TaskType.Read) ? (
                    <DialogTaskRead
                      audio={audios?.[idx]}
                      textFromSpeech={textFromSpeech}
                      onClickRecording={onRecordClick}
                      audioStatus={audioStatus}
                      sentence={sentence}
                      isSpeaking={isSpeaking}
                      onSuccess={() => {
                        reset();
                        delay(() => setActiveSentIdx((prev) => prev + 1), 1000);
                      }}
                    />
                  ) : [TaskType.VoiceTranslate, TaskType.Translate].includes(sentence.tasks[0]) ? (
                    <DialogTaskTranslate
                      lesson={lesson}
                      audio={audios?.[idx]}
                      textFromSpeech={textFromSpeech}
                      onClickRecording={onRecordClick}
                      audioStatus={audioStatus}
                      sentence={sentence}
                      isSpeaking={isSpeaking}
                      onSuccess={() => {
                        reset();
                        delay(() => setActiveSentIdx((prev) => prev + 1), 1000);
                      }}
                    />
                  ) : (
                    <DialogTaskOptions
                      audio={audios?.[idx]}
                      storyId={taskSentence.linkedStoryId}
                      sentence={sentence}
                      onSuccess={onSuccess}
                    />
                  )}
                </div>
              </Flex>
            </Element>
          ))}
        </Flex>
      </div>

      <TaskPanel
        sentId={taskSentence.id}
        lessonId={lesson.id}
        task={TaskType.ActiveDialog}
        setDictOpened={setDictOpened}
        showGrammar={showGrammar}
        storyId={storyId}
        onNext={onNext}
        isCompleted={status === StatusType.Completed}
      >
        <Popconfirm
          title={"Начать заново?"}
          onConfirm={() => {
            setActiveSentIdx(-1);
            delay(() => setActiveSentIdx(0), 0);
          }}
        >
          <Button icon={<ReloadOutlined />} />
        </Popconfirm>
      </TaskPanel>

      <Dictionary storyId={storyId} isOpen={isDictOpened} sentences={sentences} toggle={setDictOpened} />
      <div className={styles.placeholder} />
      <HintDrawer
        text={sentences[activeSentIdx]?.hint}
        open={hintDrawerStatus === "active"}
        onClose={() => setHintDrawerStatus("closed")}
      />
    </div>
  );
};

export default DialogTask;
