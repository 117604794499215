import React, { FC, useEffect, useMemo, useState } from "react";
import { StatusType, TagType, TaskType } from "App.types";
import { EmptySpaceTags, SkipTags, successMessage, TagsToMerge } from "App.constants";
import { Button, notification, Space } from "antd";
import API from "Api";
import { capitalize, difference, reject, shuffle, sortBy } from "lodash";
import { SoundOutlined, SwapLeftOutlined } from "@ant-design/icons";
import { usePressEnter } from "./Helpers/usePressEnter";
import cx from "classnames";
import { SentenceTaskProps } from "./SentenceTask.type";
import { useAudioTranscript } from "./Helpers/useAudioTranscript";
import styles from "./SentenceTask.module.scss";
import { DragDropContext, Draggable, Droppable, DropResult } from "react-beautiful-dnd";
import { arrayMove } from "./SentenceTask.helpers";
import { isEqualEnglishWords } from "App.helpers";
import { isMobile } from "react-device-detect";
import AudioButton from "Components/AudioButton";
import TaskPanel from "Components/TaskPanel";

const AudioCollectTaskDnd: FC<SentenceTaskProps> = ({
  sentence,
  sentence: { text, tags, translate, id, storyId },
  lesson,
  onTaskComplete,
  onNext,
  play,
  children,
  audio,
  setDictOpened,
  showSuccessMessage = true,
  setTry,
  showGrammar,
  autoHeight,
  transcripts,
}) => {
  const [status, setStatus] = useState<StatusType>(StatusType.Empty);
  const [activeLeaf, setActiveLeaf] = useState<number | null>(null);
  const [selectedTags, setSelectedTags] = useState<number[]>([]);
  const [wrongTags, setWrongTags] = useState<number[]>([]);

  const [notifyApi, contextHolder] = notification.useNotification({ placement: "bottom", bottom: 90 });

  const shuffledTags = useMemo(() => {
    let audioIdx = 0;
    const tagsWithAudioIdx = reject(tags, (t, idx) => t.word === "." && idx === tags.length - 1).map((tag) => ({
      ...tag,
      audioIdx: text.includes(` ${tag.word}`) ? ++audioIdx : audioIdx,
    }));

    const items = tagsWithAudioIdx.map((t, idx) => {
      return { ...t, idx, word: idx === 0 && !t.pos?.includes("NNP") && t.word !== "I" ? t.word.toLowerCase() : t.word };
    });

    const shuffled = shuffle(reject(items, (t) => difference([",", ".", "?", "!"], [...EmptySpaceTags]).includes(t.word)));

    return sortBy(shuffled, (t) => [",", ".", "?", "!"].includes(t.word));
  }, [tags, text]);

  const tagsToSelect = useMemo(() => shuffledTags.filter((t) => !selectedTags.includes(t.id)), [shuffledTags, selectedTags]);

  // initial
  useEffect(() => {
    setActiveLeaf(null);
    setStatus(StatusType.Editing);
    setSelectedTags([]);
  }, [sentence.id, tags]);

  // audio transcript
  useAudioTranscript({ audio, sentence, setActiveLeaf, transcripts });

  const onCheck = (textAnswer: string = "", isHintClicked: boolean = false): Promise<StatusType> => {
    let hasError = false;
    const errorTags: number[] = [];

    if (tagsToSelect.some((t) => !EmptySpaceTags.includes(t.word))) {
      //if (!isHintClicked) notifyApi.warning({ message: "Выбраны не все слова!" });
      hasError = true;
    }

    selectedTags.forEach((userTagId, index) => {
      const userTag = tags.find((t) => t.id === userTagId);

      if (!isEqualEnglishWords(tags[index]?.word, userTag?.word)) {
        setWrongTags((prev) => [...prev, userTagId]);
        errorTags.push(userTagId);
        hasError = true;
      }
    });

    if (!hasError) {
      setStatus(StatusType.Completed);
      notifyApi.destroy();
      showSuccessMessage && notifyApi.success(successMessage);
      onTaskComplete();
      setSelectedTags(tags.map((t) => t.id));
      return Promise.resolve(StatusType.Completed);
    }

    if (!isHintClicked)
      notifyApi.warning({
        message: "Есть ошибки",
        duration: 2,
      });

    setStatus(StatusType.Order);

    if (selectedTags.length && !isHintClicked) {
      API.event.save({
        sentence: { id: sentence.id },
        text: selectedTags.map((id) => tags.find((t) => t.id === id)?.word).join(" "),
        type: "mistake",
        task: TaskType.Collect,
        lesson: { id: lesson.id },
      });
    }
    return Promise.reject(errorTags);
  };

  const onHintAnswer = () => {
    const text = selectedTags.map((id) => tags.find((t) => t.id === id)?.word).join(" ");
    API.event.save({ text, type: "hint", task: TaskType.Collect, lesson: { id: lesson.id }, sentence: { id: sentence.id } });

    onCheck(text, true).catch((errorTags) => {
      setTry(true);

      if (errorTags.length) {
        selectedTags.every((userTagId, idx) => {
          const userTag = tags.find((t) => t.id === userTagId);
          const rightTag = tags[idx];

          if (!isEqualEnglishWords(userTag?.word, rightTag.word)) {
            if (selectedTags.includes(rightTag.id)) {
              setSelectedTags((prev) => {
                const newIdx = prev.findIndex((pt) => pt === rightTag.id);
                let newArray = arrayMove(prev, newIdx, idx);
                newArray = arrayMove(newArray, idx + 1, newIdx);
                const wrongIdx = newArray.findIndex((wIdx, index) => tags[index].id !== wIdx);
                return wrongIdx > 0 ? newArray.slice(0, wrongIdx) : newArray;
              });
              return false;
            } else {
              setSelectedTags((prev) => {
                prev.splice(idx, 0, rightTag.id);
                const wrongIdx = prev.findIndex(
                  (tId, index) => !isEqualEnglishWords(tags[index].word, tags.find((t) => t.id === tId)?.word),
                );

                return wrongIdx > 0 ? prev.slice(0, wrongIdx) : [...prev];
              });
            }
            return false;
          }
          return true;
        });
      } else {
        const nextTag = tags[selectedTags.length];
        setSelectedTags((prev) => [...prev, nextTag.id]);
      }
    });
  };

  usePressEnter({ isCompleted: status === StatusType.Completed, onNext, onCheck });

  useEffect(() => {
    setWrongTags([]);
  }, [selectedTags]);

  const handleDragEnd = ({ destination, draggableId, source }: DropResult) => {
    if (source.droppableId === "answer" && destination?.droppableId === "answer") {
      // selectedTags.splice(source.index, 1, selectedTags.splice(destination.index, 1, selectedTags[source.index])[0]);
      setSelectedTags((prev) => arrayMove(prev, source.index, destination.index));
    }

    const tag = tagsToSelect.find((t) => `${t.id}` === draggableId);

    if (tag && isMobile && destination?.droppableId === "options" && source?.droppableId === "options") {
      setSelectedTags((prev) => [...prev, tag.id]);
    }

    if (tag && destination?.droppableId === "answer") {
      selectedTags.splice(destination.index, 0, tag.id);
      setSelectedTags((prev) => [...prev]);
    }
  };

  return (
    <DragDropContext onDragEnd={handleDragEnd} onDragStart={() => setWrongTags([])}>
      <div className={styles.sentenceTask}>
        <div
          className={cx(styles.content, styles.content_collectTask, {
            [styles.content_hasChildren]: !!children,
            [styles.content_autoHeight]: autoHeight,
          })}
        >
          <div className={styles.children}>{children}</div>

          <div className={styles.slate}>
            <div className={styles.slate_wrapper}>
              <Droppable droppableId={"answer"} direction={"horizontal"}>
                {(provided, snapshot) => (
                  <div
                    className={cx(styles.textArea, styles.answer)}
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    // isDraggingOver={snapshot.isDraggingOver}
                  >
                    {selectedTags.map((id, index) => {
                      const tag = tags.find((t) => t.id === id);
                      return (
                        <Draggable isDragDisabled={status === StatusType.Completed} key={id} index={index} draggableId={`${id}`}>
                          {(draggableProvided, snapshot) => (
                            <span
                              className={cx(styles.tag, {
                                [styles.tag__mobile]: isMobile,
                                [styles.tag__merged]: [...TagsToMerge, ...SkipTags].includes(tag?.word || ""),
                                [styles.tag__selected]: activeLeaf === index,
                                [styles.tag__wrong]: wrongTags.includes(tag?.id || 0),
                              })}
                              ref={draggableProvided.innerRef}
                              {...draggableProvided.draggableProps}
                              {...draggableProvided.dragHandleProps}
                              onClick={() => setSelectedTags((prev) => prev.filter((t) => t !== id))}
                            >
                              {index
                                ? (capitalize(tag?.lemma) === tag?.lemma && !tag.pos?.includes("PRP")) || tag?.word === "I"
                                  ? tag?.word
                                  : tag?.word.toLowerCase()
                                : capitalize(tag?.word)}
                            </span>
                          )}
                        </Draggable>
                      );
                    })}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>

              {status === "completed" ? (
                <Button
                  size={"small"}
                  type={"text"}
                  className={styles.btn__clear}
                  icon={<SoundOutlined style={{ fontSize: 22 }} />}
                  onClick={() => play?.() || audio?.play()}
                />
              ) : (
                <Button
                  size={"small"}
                  type={"text"}
                  className={styles.btn__clear}
                  icon={<SwapLeftOutlined style={{ fontSize: 24 }} />}
                  onClick={() =>
                    setSelectedTags((prev) => {
                      prev.splice(-1);
                      return [...prev];
                    })
                  }
                />
              )}
            </div>
            <div className={styles.translate}>{status === StatusType.Completed && translate}</div>
          </div>

          <div className={styles.optionsWrapper}>
            <Droppable droppableId={"options"} isDropDisabled direction={"horizontal"}>
              {(provided, snapshot) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  className={styles.options}
                  // isDraggingOver={snapshot.isDraggingOver}
                >
                  {
                    // @ts-ignore
                    tagsToSelect.map((t: TagType & { options: any[]; idx: number }, index) => (
                      <Draggable key={t.id} index={index} draggableId={`${t.id}`}>
                        {(provided, snapshot) => (
                          <span
                            onClick={() => setSelectedTags((prev) => [...prev, t.id])}
                            className={cx(styles.tag, styles.tag__option, { [styles.tag__mobile]: isMobile })}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            {t.word}
                          </span>
                        )}
                      </Draggable>
                    ))
                  }
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </div>
        </div>

        <TaskPanel
          lessonId={lesson.id}
          task={TaskType.AudioCollect}
          sentId={id}
          storyId={storyId}
          onNext={onNext}
          isCompleted={status === StatusType.Completed}
          onHint={onHintAnswer}
          setDictOpened={setDictOpened}
          audio={audio}
          showGrammar={showGrammar}
        >
          <Space>
            <Button disabled={!!tagsToSelect.length} type={"primary"} shape={"round"} onClick={() => onCheck()}>
              проверить
            </Button>
            <AudioButton audio={audio} />
          </Space>
        </TaskPanel>

        {contextHolder}
      </div>
    </DragDropContext>
  );
};

export default AudioCollectTaskDnd;
