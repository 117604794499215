import React, { Dispatch, FC, SetStateAction, useContext, useMemo } from "react";
import { BookOutlined, BulbOutlined, EditOutlined, QuestionOutlined, SoundOutlined } from "@ant-design/icons";
import { Button, Dropdown } from "antd";
import { Link } from "react-router-dom";
import { UserContext } from "App";
import { useIsBeginner } from "Hooks/useIsBeginner";

type Props = {
  storyId: number | string | undefined;
  isCompleted?: boolean;
  sentId?: number | string;
  play?: () => any;
  audio?: HTMLAudioElement;
  // showComments?: Dispatch<SetStateAction<boolean>>;
  showGrammar?: Dispatch<SetStateAction<boolean>>;
  openDictionary?: Dispatch<SetStateAction<boolean>>;
  onHintAnswer?: Function | undefined;
};

const SentenceTaskHelpButton: FC<Props> = ({ storyId, sentId, onHintAnswer, play, audio, isCompleted, openDictionary, showGrammar }) => {
  const user = useContext(UserContext);

  const isBeginner = useIsBeginner();

  const items = useMemo(() => {
    return [
      user?.isAdmin && {
        key: "admin",
        icon: <EditOutlined />,
        label: (
          <Link
            to={
              sentId
                ? `${process.env.REACT_APP_EDIT}/stories/${storyId}/sentence/${sentId}`
                : `${process.env.REACT_APP_EDIT}/stories/${storyId}`
            }
            target="_blank"
          >
            edit
          </Link>
        ),
      },
      (audio || play) && {
        key: 2,
        label: isBeginner ? "сказать" : "say it",
        icon: <SoundOutlined />,
        onClick: () => play?.() || audio?.play(),
      },
      openDictionary && {
        key: 0,
        label: isBeginner ? "словарь" : "dictionary",
        icon: <BookOutlined />,
        onClick: () => openDictionary?.(true),
      },
      showGrammar && {
        key: 3,
        label: isBeginner ? "грамматика" : "grammar",
        icon: <BookOutlined />,
        onClick: () => showGrammar?.(true),
      },
      !isCompleted &&
        onHintAnswer && {
          key: 1,
          label: isBeginner ? "подсказать" : "hint",
          icon: <BulbOutlined />,
          onClick: onHintAnswer,
        },
    ].filter((el) => el);
  }, [user?.isAdmin, sentId, storyId, audio, play, isBeginner, openDictionary, showGrammar, isCompleted, onHintAnswer]);

  return (
    <Dropdown
      menu={{
        // @ts-ignore
        items,
      }}
      trigger={["click"]}
    >
      <Button icon={<QuestionOutlined />} size={"large"} />
    </Dropdown>
  );
};

export default SentenceTaskHelpButton;
