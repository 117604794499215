import { Editable, Slate, withReact } from "slate-react";
import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { CustomText, StatusType, TaskType } from "App.types";
import { withHistory } from "slate-history";
import { createEditor, Transforms } from "slate";
import { Button, Flex, notification } from "antd";
import API from "Api";
import { getInitialMaskedSlate, isEqualText, withCustomLogic } from "App.helpers";
import { CloseOutlined, SoundOutlined } from "@ant-design/icons";
import { useQuery } from "@tanstack/react-query";
import SentenceMaskedLeaf from "./SentenceLeaf";
import styles from "./SentenceTask.module.scss";
import cx from "classnames";
import { SentenceTaskProps } from "./SentenceTask.type";
import { useAudioTranscript } from "./Helpers/useAudioTranscript";
import { successMessage, TagsToMerge } from "App.constants";
import { capitalize, shuffle, uniq, values } from "lodash";
import { isMobile } from "react-device-detect";
import { animateScroll } from "react-scroll";
import TaskPanel from "Components/TaskPanel";
import useCompletedSlate from "./Helpers/useCompletedSlate";

const initialValue: any[] = [{ children: [{ text: "" }] }];

const SelectTask: FC<SentenceTaskProps> = ({
  sentence,
  sentence: { id, text, tags, translate, alternatives, wordGroup, markers, storyId, optionStory },
  lesson,
  onTaskComplete,
  play,
  children,
  audio,
  alignCenter = false,
  setTry,
  noTranslate,
  noText,
  transcripts,
  showGrammar,
  setDictOpened,
  showSuccessMessage,
  onNext,
}) => {
  const [status, setStatus] = useState<StatusType>(StatusType.Editing);
  const [selectedAnswer, setSelectedAnswer] = useState<string>();
  const [activeLeaf, setActiveLeaf] = useState<number | null>(null);

  const editor = useMemo(() => withReact(withHistory(withCustomLogic(createEditor()))), []);
  const [notifyApi, contextHolder] = notification.useNotification({ placement: "bottom", bottom: 90, maxCount: 1 });

  const maskedLemma = useMemo(() => tags.find((t) => t.isMasked)?.lemma?.toLowerCase(), [tags]);
  const maskedAnswer = useMemo(
    () =>
      tags
        .filter((t) => t.isMasked)
        .map((t) => (TagsToMerge.includes(t.word) ? t.word : ` ${t.word}`))
        .join("")
        .trim(),
    [tags],
  );

  const reducedWords = useMemo(() => {
    const alterWords = alternatives.filter((alt) => !alt.media && alt.text).map((alt) => alt.text);

    if (alterWords.length) return uniq(shuffle([maskedAnswer || text, ...alterWords])).filter((el) => el);

    const fromOptionStory = optionStory?.sentences.map((s) => s.text) ?? [];
    const filtered = shuffle([...fromOptionStory, ...(wordGroup?.words.filter((w) => w !== (maskedAnswer || text)) ?? [])]);
    return uniq([maskedAnswer || text, ...filtered.slice(0, (lesson.selectLimit ?? 6) - 1)].sort()).filter((el) => el);
  }, [alternatives, wordGroup, maskedAnswer, text, optionStory?.sentences, lesson.selectLimit]);

  const { data: forms }: any = useQuery({
    queryKey: wordGroup ? [maskedLemma, "verbForms", ...wordGroup.words] : ["wordGroup"],
    enabled: !!wordGroup?.system,
    queryFn: () => wordGroup && API.wordGroup.getForms([maskedLemma || ""]),
    staleTime: Infinity,
    select: (data) => data?.[0],
  });

  const words = useMemo(() => {
    if (wordGroup?.system && forms) {
      switch (wordGroup.title) {
        case "-es, -s":
          return [forms?.base, forms?.es];
        case "-es, -s + V2":
          return [forms?.base, forms?.es, forms?.v2];
        case "v1_v2":
          return uniq([forms?.base, forms?.v2]);
        case "v1_v2_v3_v4":
          return uniq([forms?.base, forms?.v2, forms?.v3, forms?.es]);
        case "v1_v2_v3_v4_v5":
          return uniq([forms?.base, forms?.v2, forms?.v3, forms?.es, forms?.ing]);
        default:
          return uniq(values(forms).filter((value) => value));
      }
    }
    return reducedWords;
  }, [wordGroup, reducedWords, forms]);

  const setInitialState = useCallback(() => {
    editor.children.forEach(() => {
      Transforms.delete(editor, { at: [0] });
    });

    editor.children = [];
    const maskedIdx = tags.findIndex((tag) => tag.isMasked);
    if (maskedIdx > -1) {
      const maskedTags = tags.filter((t) => t.isMasked);
      if (maskedTags.length > 1) {
        const mergedTags = tags
          .filter((t, idx) => idx === maskedIdx || !t.isMasked)
          .map((t) => (t.isMasked ? { ...t, word: maskedAnswer } : t));
        Transforms.insertNodes(editor, [{ children: getInitialMaskedSlate(mergedTags, text) }]);
      } else {
        Transforms.insertNodes(editor, [{ children: getInitialMaskedSlate(tags, text) }]);
      }
    } else {
      Transforms.insertNodes(editor, [{ children: [{ text: "", answer: text }] }]);
    }
  }, [editor, tags, maskedAnswer, text]);

  const setCompletedState = useCompletedSlate({ tags, text, editor });

  // initial
  useEffect(() => {
    setActiveLeaf(null);
    setStatus(StatusType.Editing);
    setInitialState();
  }, [sentence.id, setInitialState, setCompletedState]);

  // audio transcript
  useAudioTranscript({ audio, sentence, setActiveLeaf, transcripts });

  const onComplete = useCallback(
    (showMessage = true, answer: string = "") => {
      setStatus(StatusType.Completed);

      if (showMessage && showSuccessMessage) {
        notifyApi.success(successMessage);
      }

      onTaskComplete(answer);
    },
    [onTaskComplete, notifyApi, showSuccessMessage],
  );

  const onCheck = useCallback(
    async (textAnswer: string = "", isHintClicked: boolean = false): Promise<StatusType> => {
      isMobile && animateScroll.scrollToTop();

      notifyApi.destroy();

      let maskedIdx = editor.children[0]?.children.findIndex((t: any) => t.isMasked);
      if (maskedIdx === -1) maskedIdx = 0;

      if (
        markers.includes("anySelect") ||
        textAnswer?.toLowerCase().trim() === editor.children[0]?.children[maskedIdx].answer?.toLowerCase().trim()
      ) {
        onComplete(false, textAnswer);
        audio?.play();
        return StatusType.Completed;
      }

      const userAnswerText =
        textAnswer.trim() ||
        editor.children[0]?.children
          ?.map((el: CustomText) => el.text)
          .join(" ")
          .trim() ||
        "";

      setTry();
      setStatus(StatusType.Error);

      if (!textAnswer) {
        Transforms.setNodes(editor, { status: "empty" }, { at: [0, maskedIdx] });
      } else {
        const [taggerAnswer] = await API.lemma.get(textAnswer);
        Transforms.setNodes(
          editor,
          { status: isEqualText(taggerAnswer.lemma, editor.children[0]?.children[maskedIdx]?.lemma) ? "lemma" : "wrong" },
          { at: [0, maskedIdx] },
        );
      }

      if (userAnswerText.trim() && !isHintClicked) {
        API.event.save({
          sentence: { id: sentence.id },
          text: userAnswerText,
          type: "mistake",
          task: TaskType.Collect,
          lesson: { id: lesson.id },
        });
      }
      return StatusType.Error;
    },
    [editor, lesson, notifyApi, onComplete, sentence, setTry, audio, markers],
  );

  const renderLeaf = useCallback(
    (props: any) => (
      <SentenceMaskedLeaf
        showMasked
        hideMasked={status === StatusType.Completed}
        underlined={props.leaf.audioIdx === (activeLeaf ?? -1)}
        showErrors={!["editing", "completed", "loading", "isRecording", ""].includes(status)}
        {...props}
      />
    ),
    [status, activeLeaf],
  );

  const onHintAnswer = useCallback(async () => {
    const text = editor.children[0]?.children?.map((el: CustomText) => el.text).join(" ");
    API.event.save({ text, type: "hint", task: TaskType.Collect, lesson: { id: lesson.id }, sentence });

    const result = await onCheck(text, true);
    if (result !== StatusType.Completed) {
      setTry(true);
      let maskedIdx = editor.children[0]?.children.findIndex((t: any) => t.isMasked);
      if (maskedIdx === -1) maskedIdx = 0;

      const { answer } = editor.children[0]?.children[maskedIdx];
      Transforms.insertText(editor, answer, { at: [0, maskedIdx] });
      Transforms.setNodes(editor, { status: "success" }, { at: [0, maskedIdx] });

      onComplete(false);
    }
  }, [editor, lesson.id, onCheck, onComplete, sentence, setTry]);

  // usePressEnter({ status, onNext, onCheck });

  const onReset = () => {
    setInitialState();
  };

  const onWordGroupClick = (t: string) => (e: any) => {
    const maskedIdx = editor.children[0]?.children.findIndex((el: CustomText) => el.isMasked) ?? -1;

    setSelectedAnswer(t);
    setStatus(StatusType.Editing);
    const word = maskedIdx === 0 && capitalize(text) === text ? capitalize(e.key || t) : e.key || t;
    Transforms.insertText(editor, word, { at: [0, maskedIdx === -1 ? 0 : maskedIdx] });

    onCheck(word);
  };

  return (
    <div className={styles.sentenceTask}>
      <div className={cx(styles.content, styles.content_autoHeight)}>
        <div className={styles.children}>{children}</div>

        {(translate || tags.some((t) => t.isMasked)) && (
          <div className={cx(styles.slate, { [styles.slate_alignCenter]: alignCenter })}>
            {!noText && (
              <div className={styles.slate_wrapper}>
                {initialValue && (
                  <form spellCheck="false">
                    <Slate editor={editor} initialValue={initialValue}>
                      <Editable className={styles.textArea} readOnly={true} renderLeaf={renderLeaf} />
                    </Slate>
                  </form>
                )}
                {status === "completed" ? (
                  <Button
                    size={"small"}
                    type={"text"}
                    className={styles.btn__clear}
                    icon={<SoundOutlined style={{ fontSize: 22 }} />}
                    onClick={() => play?.() || audio?.play()}
                  />
                ) : (
                  <Button
                    size={"small"}
                    type={"text"}
                    className={styles.btn__clear}
                    icon={<CloseOutlined style={{ fontSize: 12 }} />}
                    onClick={onReset}
                  />
                )}
              </div>
            )}
            {translate && <div className={styles.translate}>{(!noTranslate || status === StatusType.Completed) && translate}</div>}
          </div>
        )}

        <div className={styles.bottom}>
          <Flex gap={10} wrap={"wrap"} align={"center"} className={styles.select}>
            {words?.map((t: string) => (
              <span
                // size={isMobile ? "middle" : undefined}
                key={t}
                className={cx(styles.btnSelect, {
                  [styles.btn_disabled]: status === StatusType.Completed,
                  [styles.btn_red]: t === selectedAnswer && status === StatusType.Error,
                  [styles.btn_green]: status === StatusType.Completed && t === selectedAnswer,
                  [styles.btn_bold]: status === StatusType.Completed && t === selectedAnswer && markers.includes("anySelect"),
                })}
                onClick={onWordGroupClick(t)}
              >
                {t}
              </span>
            ))}
          </Flex>
        </div>
      </div>

      <TaskPanel
        lessonId={lesson.id}
        task={TaskType.Select}
        sentId={id}
        storyId={storyId}
        onNext={onNext}
        isCompleted={status === StatusType.Completed}
        setDictOpened={setDictOpened}
        audio={audio}
        showGrammar={showGrammar}
        onHint={onHintAnswer}
      ></TaskPanel>

      {contextHolder}
    </div>
  );
};

export default SelectTask;
