import { SentenceType, TaskType } from "App.types";
import styles from "./DialogTaskOptions.module.scss";
import React, { FC, useContext, useEffect, useMemo, useState } from "react";
import { shuffle } from "lodash";
import AnimateFade from "Components/AnimateFade";
import { getAlternativeAudioUrl, isEqualText } from "App.helpers";
import cx from "classnames";
import StoryDialogSentence from "Components/StoryDialogSentence";
import { UserContext } from "App";
import { useSuspenseQuery } from "@tanstack/react-query";
import API from "Api";
import Cookies from "js-cookie";
import { DEFAULT_VOICES } from "App.constants";

const DialogTaskOptions: FC<{ sentence: SentenceType; onSuccess: Function; storyId?: number; audio: HTMLAudioElement }> = ({
  sentence: { isLeft, alternatives, text, voice, tasks, wordGroup },
  sentence,
  onSuccess,
  storyId,
  audio,
}) => {
  const [selectedOption, setSelectedOption] = useState<any>();
  const isCompleted = selectedOption && !selectedOption.wrong;

  const user = useContext(UserContext);

  const { data: userProps } = useSuspenseQuery({
    queryKey: ["props", user?.id],
    queryFn: API.user.getProps,
    staleTime: Infinity,
  });

  const isUserAWoman = useMemo(() => userProps.find((p) => p.name === "GENDR")?.value === "woman", [userProps]);

  const v1 = isUserAWoman ? DEFAULT_VOICES[0] : DEFAULT_VOICES[1];
  const v2 = isUserAWoman ? DEFAULT_VOICES[1] : DEFAULT_VOICES[0];

  const token = Cookies.get("token");

  useEffect(() => {
    audio.onended = () => onSuccess({ text });
  }, [audio, onSuccess, text]);

  const options = useMemo(
    () =>
      tasks.includes(TaskType.Select) && wordGroup?.words.length
        ? wordGroup.words.map((w) => ({ text: w, wrong: !isEqualText(text, w), audio: isEqualText(text, w) ? audio : new Audio() }))
        : shuffle([
            { text, wrong: false, audio },
            ...alternatives.map((alt) => {
              const aud = new Audio(getAlternativeAudioUrl(alt.id, alt.text, voice || (isLeft ? v1 : v2), token));
              if (!alt.wrong) {
                aud.onended = () => onSuccess(alt);
              }
              return {
                ...alt,
                audio: aud,
              };
            }),
          ]).filter((opt) => opt.text.trim()),
    [tasks, wordGroup?.words, text, audio, alternatives, voice, isLeft, v1, v2, token, onSuccess],
  );

  return (
    <div className={styles.dialogTaskOptions}>
      {isCompleted ? (
        <StoryDialogSentence
          storyId={storyId}
          audio={selectedOption.audio}
          sentence={sentence}
          text={selectedOption.text}
          tags={selectedOption.tags}
          active={false}
          voice={voice || (isLeft ? v1 : v2)}
        />
      ) : (
        options.map((opt, idx) => (
          <AnimateFade key={opt.text} order={idx}>
            <div
              onClick={() => {
                opt.audio?.play();
                setSelectedOption(opt);
              }}
              className={cx(styles.option, {
                [styles.option_left]: isLeft,
                [styles.option_right]: selectedOption === opt && !opt.wrong,
                [styles.option_wrong]: selectedOption === opt && opt.wrong,
              })}
            >
              {opt.text}
            </div>
          </AnimateFade>
        ))
      )}
    </div>
  );
};

export default DialogTaskOptions;
