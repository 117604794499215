import { BrowserRouter, Route, Routes } from "react-router-dom";
import Books from "Pages/Books";
import Stories from "Pages/Stories";
import Story from "Pages/Story";
import Lessons from "Pages/Lessons";
import Lesson from "Pages/Lessons/Lesson";
import MainLayout from "Components/MainLayout";
import Book from "Pages/Books/Book";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { API_URL, clientId } from "App.constants";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import BookLesson from "Pages/Books/BookLesson";
import { ConfigProvider, notification, ThemeConfig } from "antd";
import { createContext, useEffect, useMemo, useState } from "react";
import { isMobile } from "react-device-detect";
import VideoPage from "Pages/VideoPage";
import ErrorBoundary from "Components/ErrorBoundary";
import LoginModal from "Components/MainLayout/LoginModal";
import "App.css";
import BookMobile from "Pages/Books/BookMobile";
import YandexToken from "Pages/YandexToken";
import axios from "axios";
import Cookies from "js-cookie";
import SpeechLesson from "Pages/Lessons/SpeechLesson";
import LessonsGrammar from "Pages/Lessons/LessonsGrammar";
import LessonReview from "Pages/Lessons/LessonReview";
import Courses from "Pages/Courses";
import MainPage from "Pages/MainPage";
import Profile from "Pages/Profile";
import { UserType } from "App.types";
import Vocabulary from "Pages/Vocabulary";
import GoogleToken from "Pages/GoogleToken";
import AppleToken from "Pages/AppleToken";
import IntroLessonNew from "Pages/Lessons/IntroLessonNew";
import { NotificationInstance } from "antd/es/notification/interface";

export const instance = axios.create({
  baseURL: API_URL,
  headers: { Authorization: `Bearer ${Cookies.get("token")}` },
});

const queryClient = new QueryClient({ defaultOptions: { queries: { retry: false, refetchOnWindowFocus: false } } });

// type UserContextType = { name: string; picture: string; lang: "en" | "de"; isEnglish: boolean; activeCourse: number };
export const UserContext = createContext<UserType | undefined>(undefined);
export const NotifyContext = createContext<NotificationInstance | undefined>(undefined);

function App() {
  const [user, setUser] = useState<UserType | undefined>(() => {
    const userString = Cookies.get("user");
    return userString ? JSON.parse(userString) : undefined;
  });

  const theme = useMemo<ThemeConfig>(
    () => (isMobile ? { token: { fontSize: 18 }, components: { Button: { controlHeightLG: 55, fontSizeLG: 24 } } } : { token: {} }),
    [],
  );

  useEffect(() => {
    if (isMobile) {
      document.body.classList.add("mobile");
    }
  }, []);

  const [notifyApi, contextHolder] = notification.useNotification({ placement: "bottom", bottom: 90 });

  return (
    <UserContext.Provider value={user}>
      <NotifyContext.Provider value={notifyApi}>
        <GoogleOAuthProvider clientId={clientId}>
          <ErrorBoundary fallback={<LoginModal axiosInstance={instance} open setUser={setUser} />}>
            <QueryClientProvider client={queryClient}>
              <ConfigProvider componentSize={"large"} theme={theme}>
                <BrowserRouter>
                  <Routes>
                    <Route path={"apple"} element={<AppleToken axiosInstance={instance} setUser={setUser} />} />
                    <Route path={"yandex"} element={<YandexToken axiosInstance={instance} setUser={setUser} />} />
                    <Route path={"google"} element={<GoogleToken axiosInstance={instance} setUser={setUser} />} />

                    <Route path={"/"} element={<MainLayout axiosInstance={instance} user={user} setUser={setUser} />}>
                      <Route element={<Profile setUser={setUser} />} path={"/profile"} />

                      <Route element={<Vocabulary />} path={"/vocabulary"} />

                      <Route element={<Courses />} path={"/courses"} />

                      <Route element={<LessonReview />} path="course/:course/lessons/review/exercise/:id" />

                      <Route element={<VideoPage />} path="/video/:title" />

                      <Route element={<Stories />} path="/stories" />
                      <Route element={<Story />} path="/stories/:title" />

                      <Route element={<Books />} path="books" />
                      <Route element={isMobile ? <BookMobile /> : <Book />} path="books/:title/:page?" />

                      <Route element={<Lesson />} path="course/grammar/:course/lessons/exercise/:id/:sentId?" />
                      <Route element={<Lesson />} path="course/:course/lessons/exercise/:id/:sentId?" />

                      <Route element={<Lesson />} path="course/grammar/:course/lessons/training/:id/:sentId?" />
                      <Route element={<Lesson />} path="course/:course/lessons/training/:id/:sentId?" />

                      <Route element={<IntroLessonNew />} path="course/:course/lessons/intro/:id/:storyId" />

                      <Route element={<SpeechLesson />} path="course/grammar/:course/lessons/speech/:id" />
                      <Route element={<SpeechLesson />} path="course/:course/lessons/speech/:id/:sentId?" />
                      <Route element={<SpeechLesson review />} path="course/:course/lessons/review/speech/:id" />

                      <Route element={<Lesson />} path="/card/:id" />
                      <Route element={<Story />} path="course/grammar/:course/lessons/story/:id" />
                      <Route element={<Story />} path="course/:course/lessons/story/:id" />

                      <Route element={<BookLesson />} path="course/grammar/:course/lessons/book/:id" />
                      <Route element={<BookLesson />} path="course/:course/lessons/book/:id" />

                      <Route element={<Lessons />} path={"lessons"} />
                      <Route element={<LessonsGrammar />} path={"course/grammar/:course"} />
                      <Route element={<Lessons />} path={"course/:course"} />

                      <Route path="/books" element={<Books />} />

                      <Route path="/" element={<MainPage />} />
                    </Route>
                  </Routes>
                </BrowserRouter>
                {contextHolder}
              </ConfigProvider>
            </QueryClientProvider>
          </ErrorBoundary>
        </GoogleOAuthProvider>
      </NotifyContext.Provider>
    </UserContext.Provider>
  );
}

export default App;
